<template>
  <div class="py-3 d-flex justify-content-center"
       style="background-color: #f2f2f2;    border-bottom: #428bca 10px solid;">
    <div class="w1180 bg-white p-4 my-4">
      <CasesHeader :c_title="info.title" :c_date="info.date"/>

      <div class="x12 text-big">
        <p>加拿大 安省雇主担保项目<br/>
          安省试点EOI新政后首发成功案例，32天获批省提名！<br/>
          客户40多岁，多年财务经验，一直在跨国企业担任高管<br/>
          2021.5.25 获得EOI邀请<br/>
          2021.6.4 提交申请（上周系统显示Decision in progress）<br/>
          2021.7.6 迎来省提名获批</p>
        <p><img loading="lazy" class="aligncenter size-full wp-image-23195" src="../../assets/Cases/20/1.jpg" alt=""
                width="600" height="776"/></p>
        <p><img loading="lazy" class="aligncenter size-full wp-image-23194" src="@/assets/Cases/20/2.jpg" alt=""
                width="600" height="531"/></p>
        <p>&nbsp;</p>
        <p><strong>加拿大安省雇主担保移民 – 移民加拿大快车道</strong></p>
        <p><strong>安省雇主担保移民项目优势</strong></p>
        <p>一人申请，全家移民</p>
        <p>无需投资，无需面试，全程在国内提交申请没有资产要求，无需解释资金来源EOI系统对学历和语言没有打分</p>
        <p>3-4个月获批省提名，可工签提前登陆安省，享受加拿大优质教育资源</p>
        <p>省雇主担保移民项目申请条件</p>
        <p>针对人群：由资深经验的国内人士（建议50岁以下）学历要求：国内大专以上（建议）</p>
        <p>工作经验：申请前5年内有2年的有效工作经验（必须）</p>
        <p><strong>其他要求：</strong></p>
        <p>1、申请人在获得一份符合职业类别NOC0/A/B的全职工作；</p>
        <p>2、工资符合同省份范围内该职位的普遍公司标准；</p>
        <p>3、申请前2年有效工作经验与目标职业的NOC类别相同；</p>
        <p>&nbsp;</p>
        <p style="text-align: center;">加拿大，一个少有所教、老有所养、</p>
        <p style="text-align: center;">病有所医的国家</p>
        <p style="text-align: center;">您心动了吗？</p>
        <p style="text-align: center;">如果您想了解自己是否符合申请条件</p>
        <p style="text-align: center;">可拨打免费咨询热线：</p>
        <p style="text-align: center;">13764320113</p>
        <p style="text-align: center;">了解更多详细内容，我们将安排专业移民顾问为您解答。</p>
      </div>

      <CasesFooter class="mt-5" :index="info.index"/>
    </div>
  </div>

</template>

<script>
import CasesHeader from "@/components/Cases/CasesHeader";
import CasesFooter from "@/components/Cases/CasesFooter";

export default {
  name: "Cases20",
  components: {CasesFooter, CasesHeader},
  data() {
    return {
      info: this.$route.meta,
    }
  }
}
</script>

<style scoped>
.aligncenter {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

a {
  color: #428bca;
  text-decoration: none;
}
</style>